<template>
  <base-header class="">
    <div class="row align-items-center pb-4">
      <div class="col-lg-6 col-7">
        <nav
          aria-label="breadcrumb"
          class="d-none d-md-inline-block hidden_after"
        >
          <route-bread-crumb></route-bread-crumb>
        </nav>
      </div>
      <div class="col-lg-6 col-5 d-none d-md-inline-block">
        <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>
        <!-- <base-button size="sm" type="neutral">New</base-button>
          <base-button size="sm" type="neutral">Filters</base-button> -->
      </div>
    </div>
    <!-- Card stats -->
    <div class="row icons_laive">
      <template v-if="user.rol < 2">
        <div class="col-xxl-3 col-xl-4 col-md-6">
          <a href="/usuarios/listado">
            <stats-card
              title="Usuarios"
              type="gradient-laive"
              sub-title=""
              icon="ni ni-users"
            >
            </stats-card>
          </a>
        </div>
      </template>
      <div class="col-xxl-3 col-xl-4 col-md-6" v-if="user.rol !== 8">
        <a href="/pedidos/listado">
          <stats-card
            title="Pedidos"
            type="gradient-laive"
            icon="ni ni-pedidos"
          >
          </stats-card>
        </a>
      </div>

      <div class="col-xxl-3 col-xl-4 col-md-6" v-if="user.rol !== 8">
        <a href="/nopedidos">
          <stats-card
            title="No Pedidos"
            type="gradient-laive"
            icon="ni ni-pedidos"
          >
          </stats-card>
        </a>
      </div>
      <div class="col-xxl-3 col-xl-4 col-md-6" v-if="user.rol !== 8">
        <a href="/cobranzas/listado">
          <stats-card
            title="Cobranzas"
            type="gradient-laive"
            icon="ni ni-cobranzas"
          >
          </stats-card>
        </a>
      </div>
      <div class="col-xxl-3 col-xl-4 col-md-6" v-if="user.rol !== 8">
        <a href="/depositos/listado">
          <stats-card
            title="Depositos"
            type="gradient-laive"
            icon="ni ni-depositos"
          >
          </stats-card>
        </a>
      </div>
      <div class="col-xxl-3 col-xl-4 col-md-6" v-if="user.rol !== 8">
        <a href="/devoluciones/listado">
          <stats-card
            title="Devoluciones"
            type="gradient-laive"
            icon="ni ni-devolucion"
          >
          </stats-card>
        </a>
      </div>
      <div class="col-xxl-3 col-xl-4 col-md-6" v-if="user.rol !== 8">
        <a href="/reportes/ventas-vendedor">
          <stats-card
            title="Reporte de Ventas"
            type="gradient-laive"
            sub-title="Objetivos por Vendedor"
            icon="ni ni-reporte"
          >
          </stats-card>
        </a>
      </div>
      <div class="col-xxl-3 col-xl-4 col-md-6" v-if="user.rol !== 8">
        <a href="/reportes/ventas-categoria">
          <stats-card
            title="Reporte de Ventas"
            type="gradient-laive"
            sub-title="Objetivos por Categoria"
            icon="ni ni-reporte"
          >
          </stats-card>
        </a>
      </div>
      <template v-if="user.rol < 3">
        <div class="col-xxl-3 col-xl-4 col-md-6">
          <a href="/reportes/ventas-objetivos-vendedor">
            <stats-card
              title="Reporte de Ventas"
              type="gradient-laive"
              sub-title="Carga de Objetivos"
              icon="fas fa-file-upload"
            >
            </stats-card>
          </a>
        </div>
      </template>
      <div class="col-xxl-3 col-xl-4 col-md-6" v-if="user.rol !== 8">
        <a href="/reportes/visitas-vendedor">
          <stats-card
            title="Reportes de Visitas"
            type="gradient-laive"
            sub-title=""
            icon="ni ni-reporte"
          >
          </stats-card>
        </a>
      </div>
      <div class="col-xxl-3 col-xl-4 col-md-6" v-if="user.rol !== 8">
        <a href="/reportes/cobertura-vendedor">
          <stats-card
            title="Reportes de Cobertura Simple"
            type="gradient-laive"
            sub-title=""
            icon="ni ni-reporte"
          >
          </stats-card>
        </a>
      </div>
      <template v-if="user.rol < 3">
        <div class="col-xxl-3 col-xl-4 col-md-6">
          <a href="/reportes/cobertura-import-pesos">
            <stats-card
              title="Carga de Pesos en Cobertura Simple"
              type="gradient-laive"
              sub-title=""
              icon="fas fa-file-upload"
            >
            </stats-card>
          </a>
        </div>
      </template>
      <div class="col-xxl-3 col-xl-4 col-md-6 d-none">
        <a href="/reportes/cobertura-agregada-categoria">
          <stats-card
            title="Reportes de Cobertura Agregada"
            type="gradient-laive"
            sub-title="Objetivos por Categor�a"
            icon="ni ni-reporte"
          >
          </stats-card>
        </a>
      </div>
      <div class="col-xxl-3 col-xl-4 col-md-6 d-none">
        <a href="/reportes/cobertura-agregada-objetivo">
          <stats-card
            title="Reportes de Cobertura Agregada"
            type="gradient-laive"
            sub-title="Carga de Objetivos"
            icon="fas fa-file-upload"
          >
          </stats-card>
        </a>
      </div>
      <div class="col-xxl-3 col-xl-4 col-md-6" v-if="user.rol !== 8">
        <a href="/reportes/cobertura-crecimiento-vendedor">
          <stats-card
            title="Reportes de Crecimiento"
            type="gradient-laive"
            sub-title=""
            icon="ni ni-reporte"
          >
          </stats-card>
        </a>
      </div>
      <template v-if="user.rol < 3">
        <div class="col-xxl-3 col-xl-4 col-md-6">
          <a href="/maestro-productos/listado">
            <stats-card
              title="Maestro de Productos"
              type="gradient-laive"
              sub-title=""
              icon="fab fa-product-hunt"
            >
            </stats-card>
          </a>
        </div>
      </template>
      <template v-if="user.rol > 3">
        <div class="col-xxl-3 col-xl-4 col-md-6" v-if="user.rol !== 8">
          <a href="/maestro-productos/listado-productos-foco">
            <stats-card
              title="Productos Foco"
              type="gradient-laive"
              sub-title=""
              icon="fab fa-product-hunt"
            >
            </stats-card>
          </a>
        </div>
        <div class="col-xxl-3 col-xl-4 col-md-6" v-if="user.rol !== 8">
          <a href="/maestro-productos/listado-productos-cluster">
            <stats-card
              title="Productos Cluster"
              type="gradient-laive"
              sub-title=""
              icon="fab fa-product-hunt"
            >
            </stats-card>
          </a>
        </div>
      </template>
      <template v-if="user.rol <= 3">
        <div class="col-xxl-3 col-xl-4 col-md-6">
          <a href="/supervisores/listado">
            <stats-card
              title="Supervisores"
              type="gradient-laive"
              sub-title=""
              icon="fas fa-id-card-alt"
            >
            </stats-card>
          </a>
        </div>
      </template>
      <div class="col-xxl-3 col-xl-4 col-md-6" v-if="user.rol !== 8">
        <a href="/vendedores/listado">
          <stats-card
            title="Vendedores"
            type="gradient-laive"
            sub-title=""
            icon="fas fa-user-tag"
          >
          </stats-card>
        </a>
      </div>
      <div class="col-xxl-3 col-xl-4 col-md-6" v-if="user.rol !== 8">
        <a href="/clientes/listado">
          <stats-card
            title="Clientes"
            type="gradient-laive"
            sub-title=""
            icon="fas fa-user-check"
          >
          </stats-card>
        </a>
      </div>
      <div
        v-if="showBonificacion && accessTrade && user.rol !== 8"
        class="col-xxl-3 col-xl-4 col-md-6"
      >
        <a href="/bonificaciones/listado">
          <stats-card
            title="Bonificaciones"
            type="gradient-laive"
            sub-title=""
            icon="ni ni-reporte"
          >
          </stats-card>
        </a>
      </div>

      <div
        class="col-xxl-3 col-xl-4 col-md-6"
        v-if="
          user.rol === 1 ||
          user.rol === 2 ||
          user.rol === 3 ||
          user.rol === 4 ||
          user.rol === 8
        "
      >
        <a href="/equipos-frio/maestro-activos">
          <stats-card
            title="Equipos de frío"
            type="gradient-laive"
            sub-title=""
            icon="ni ni-reporte"
          >
          </stats-card>
        </a>
      </div>

      <div v-if="user.rol === 8" style="height: 80vh"></div>
    </div>
  </base-header>
</template>

<script>
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import DashboardNavbar from "../Layout/DashboardNavbar";

// Lists
import ActivityFeed from "./ActivityFeed";
import UserList from "./UserList";
import TaskList from "./TaskList";
import ProgressTrackList from "./ProgressTrackList";

// Tables
import LightTable from "./LightTable";
import PageVisitsTable from "./PageVisitsTable";
import SocialTrafficTable from "./SocialTrafficTable";

import { mapGetters } from "vuex";

const showBonification = process.env.VUE_APP_ENABLE_BONIFICATION === "1";

export default {
  components: {
    DashboardNavbar,
    ActivityFeed,
    UserList,
    TaskList,
    ProgressTrackList,
    PageVisitsTable,
    SocialTrafficTable,
    LightTable,
    RouteBreadCrumb,
  },
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
      accessTrade: "auth/accessTrade",
      showBonificacion: "auth/showBonification",
    }),
  },
  data() {
    return {
      showBonification,
    };
  },
};
</script>

<style lang="scss"></style>
