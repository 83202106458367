import axios from "axios";
import setting from "./setting";
import { request } from "../util/request";

const ENDPOINT_URL = `${setting.http.base_url}/Incidencia`;

export default {
  async listByFilters(token, filters) {
    const { data } = await axios.post(`${ENDPOINT_URL}/lista`, filters, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    return data;
  },
  async actualizarEstado(token, filters) {
    const { data } = await axios.post(
      `${ENDPOINT_URL}/actualizar-estado`,
      filters,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return data;
  },
  async listByEquipo(token, idEquipo) {
    return this.listByFilters(token, { idEquipo });
  },
  async downloadList(token, dataPost) {
    if (dataPost == undefined) {
      throw new Error("DataPost debe ser un objeto válido");
    }
    const { data } = await axios.post(`${ENDPOINT_URL}/exportar`, dataPost, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    if (data.status !== 200) {
      throw new Error("El archivo no fue encontrado");
    }
    const link = document.createElement("a");
    const domainApi = new URL(setting.http.base_url).origin;
    link.href = `${domainApi}/${data.message}`;
    link.click();
    return data;
  },
  async getDetail(token, id) {
    const { data } = await axios.get(`${ENDPOINT_URL}/detalle/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    return data;
  },
  async editTracking(token, body) {
    const { data } = await axios.post(
      `${setting.http.base_url}/v2/Incidencia/Editar/Tracking`,
      body,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return data;
  },
  async registerTracking(token, body) {
    return await axios.post(
      `${setting.http.base_url}/v2/Incidencia/Register/Tracking`,
      body,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
  },
  async uploadFileTracking(token, formData) {
    const { data } = await axios.post(
      `${setting.http.base_url}/v2/Incidencia/Editar/Tracking/Evidencia`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return data;
  },
  async uploadBulk(body) {
    const { data } = await request.post(
      "v2/Equipment/incidents/UploadBulk",
      body
    );
    return data;
  },
  async getFileTracking(token, id, fileName) {
    try {
      const { data } = await axios.get(
        `${ENDPOINT_URL}/Ver/Tracking/${id}/Evidencia/${fileName}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          responseType: "blob",
        }
      );

      const extension = fileName.split(".").pop().toLowerCase();

      let mimeType = "application/octet-stream"; // Tipo MIME por defecto
      if (extension === "png") {
        mimeType = "image/png";
      } else if (extension === "jpg" || extension === "jpeg") {
        mimeType = "image/jpeg";
      } else if (extension === "pdf") {
        mimeType = "application/pdf";
      }

      const blob = new Blob([data], { type: mimeType });

      const blobUrl = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = fileName;
      link.click();

      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error("Error al obtener o procesar el archivo:", error);
    }
  },
};
